import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const KeyboardArrowRight = ({ color }: IconSvgProps) => {
  return (
    <IconSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M9.22803 15.875L13.108 11.995L9.22803 8.115C9.04078 7.92817 8.93555 7.67452 8.93555 7.41C8.93555 7.14548 9.04078 6.89183 9.22803 6.705C9.61803 6.315 10.248 6.315 10.638 6.705L15.228 11.295C15.618 11.685 15.618 12.315 15.228 12.705L10.638 17.295C10.248 17.685 9.61803 17.685 9.22803 17.295C8.84803 16.905 8.83803 16.265 9.22803 15.875Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

export default KeyboardArrowRight;

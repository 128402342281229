import Head from "next/head";
import { SourceHTMLAttributes, VideoHTMLAttributes } from "react";
import styled from "styled-components";

const Container = styled.span`
  max-width: 100%;
`;

const VideoBox = styled.video`
  position: relative;
  z-index: 100;
  max-width: 100%;
`;

export type VideoSource = SourceHTMLAttributes<HTMLSourceElement>;

export default function Video({
  sources,
  ...videoProps
}: VideoHTMLAttributes<HTMLVideoElement> & {
  sources: Array<VideoSource>;
}) {
  return (
    <Container>
      <Head>
        {sources.map((oneSource) =>
          oneSource.src !== undefined && oneSource.type !== undefined ? (
            <link
              key={oneSource.src}
              rel="prefetch"
              href={oneSource.src}
              as="video"
              type={oneSource.type}
            />
          ) : undefined,
        )}
      </Head>

      <VideoBox loop muted playsInline {...videoProps}>
        {sources.map((oneSource, i) => (
          <source key={oneSource.src ?? i} {...oneSource} />
        ))}
      </VideoBox>
    </Container>
  );
}

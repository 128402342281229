"use client";

import { Trans, t } from "@lingui/macro";
import Image from "next/image";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { Flex } from "~/guidelines/Flex";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { body3 } from "~/guidelines/Typography";
import PhotoLogeBed from "~/applications/Editorials/Ui/Photos/Gogaille_FernandRabier_vert.png";
import guide_go_1 from "~/guidelines/Drawings/guide_go_01.svg";

const Wrapper = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(17)};
  padding: ${({ theme }) => theme.spacing(13, 5)};
  flex-direction: column;
  justify-content: center;
  max-width: 1440px;
  ${mediaQueries.md()} {
    padding: ${({ theme }) => theme.spacing(17)};
  }
`;

const Container = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(13)};
  align-items: flex-start;
  max-width: 1040px;
  flex-direction: column;
  ${mediaQueries.md()} {
    flex-direction: row;
    padding: 0 ${({ theme }) => theme.spacing(18)};
  }
`;

const Column = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(8)};
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
  width: 100%;
  ${mediaQueries.md()} {
    width: 475px;
    gap: ${({ theme }) => theme.spacing(17)};
    padding-top: ${({ theme }) => theme.spacing(13)};
  }
`;

const Card = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(6)};
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const Title = styled.h2`
  font-family: "Bely Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  margin: 0;
  color: ${({ theme }) => theme.color.text.blueDark};
  ${mediaQueries.md()} {
    font-size: 40px;
  }
`;

const Text = styled.p`
  margin: 0;
  ${body3}
`;

const MaxWidthImg = styled(Image)`
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center left;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  ${mediaQueries.md()} {
    width: 320px;
  }
`;

const CatchEyeBlue = styled.span`
  color: ${({ theme }) => theme.color.brand.blue};
`;

const CatchEyeRed = styled.span`
  color: ${({ theme }) => theme.color.brand.red};
  display: block;
`;

const DisplayInView = styled.div<{ $inView: boolean }>`
  opacity: ${({ $inView }) => ($inView ? 1 : 0)};
  transition: opacity 0.3s ease-in 0.1s;
`;

const Sticky = styled.div`
  position: sticky;
  top: 100px;
  width: 100%;
`;

const TopCornerImage = styled(Image)`
  position: absolute;
  right: 43px;
  top: -50px;
`;

export default function KeyBenefits() {
  const { ref, inView } = useInView({
    threshold: 0.8,
    fallbackInView: true,
  });

  return (
    <Wrapper>
      <Container>
        <Column>
          <Card>
            <Title>
              <Trans>
                A <CatchEyeBlue>standalone</CatchEyeBlue> hotel experience.
              </Trans>
            </Title>
            <Text>
              {t`Gogaille reinvents the hotel industry by connecting the charm of the old and the intelligence of the time.`}
              <br />
              {t`Result ? Rooms with autonomous access with all the modern comforts ath the heart of historical buildings.`}
            </Text>
          </Card>
          <DisplayInView ref={ref} $inView={inView}>
            <Card>
              <Title>
                <Trans>
                  But no <CatchEyeRed>Robots.</CatchEyeRed>
                </Trans>
              </Title>
              <Text>
                {t`Gogaille is above all a human adventure. At each stage of your trip, our teams ensure your comfort, the quality of your breakfast, and they obviously answer the phone if you have any questions.`}
              </Text>
            </Card>
          </DisplayInView>
        </Column>

        <Sticky>
          <TopCornerImage src={guide_go_1} alt="" />
          <MaxWidthImg
            sizes="40vw, (max-width: 768px) 100vw"
            placeholder="blur"
            src={PhotoLogeBed}
            alt={t`A room`}
          />
        </Sticky>
      </Container>
    </Wrapper>
  );
}

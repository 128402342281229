import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const ArrowBack = ({ color }: IconSvgProps) => {
  return (
    <IconSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M19.0193 11.005H7.84927L12.7293 6.12502C13.1193 5.73502 13.1193 5.09502 12.7293 4.70502C12.5424 4.51777 12.2888 4.41254 12.0243 4.41254C11.7597 4.41254 11.5061 4.51777 11.3193 4.70502L4.72927 11.295C4.33927 11.685 4.33927 12.315 4.72927 12.705L11.3193 19.295C11.7093 19.685 12.3393 19.685 12.7293 19.295C13.1193 18.905 13.1193 18.275 12.7293 17.885L7.84927 13.005H19.0193C19.5693 13.005 20.0193 12.555 20.0193 12.005C20.0193 11.455 19.5693 11.005 19.0193 11.005Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

export default ArrowBack;

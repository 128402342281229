import { Trans, t } from "@lingui/macro";
import styled from "styled-components";
import { DestinationPage } from "~/applications/OfferSearch/Domain/DestinationPage";
import SmallDestinationStampVariant from "~/components/SmallDestinationStamp";
import { ResourceVisibility } from "~/core/ResourceVisibility";
import { Stack } from "~/guidelines/Flex";
import { Loader } from "~/guidelines/Icon";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { body3 } from "~/guidelines/Typography";
import DestinationCard from "./DestinationCard";
import PoitiersMonument from "~/applications/Editorials/Ui/Destination/DestinationDrawer/Drawings/PoitiersMonument.svg";
import PhotoPoitiers from "~/applications/Editorials/Ui/Photos/Poitiers.jpg";

const Container = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(8)} ${({ theme }) => theme.spacing(5)};
  align-items: stretch;
  gap: ${({ theme }) => theme.spacing(13)};
  ${mediaQueries.md()} {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: ${({ theme }) => theme.spacing(17)}
      ${({ theme }) => theme.spacing(13)};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(7)};
  ${mediaQueries.md()} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 205px;
    grid-column-gap: ${({ theme }) => theme.spacing(7)};
    grid-row-gap: ${({ theme }) => theme.spacing(7)};

    & > div:nth-child(odd) {
      grid-row: span 3;
    }
    & > div:nth-child(even) {
      grid-row: span 2;
    }
  }
`;

const Header = styled(Stack)`
  align-items: center;
  align-self: stretch;
  gap: ${({ theme }) => theme.spacing(6)};
`;

const Title = styled.h2`
  font-family: "Bely Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  margin: 0;

  ${mediaQueries.md()} {
    font-size: 48px;
  }

  color: ${({ theme }) => theme.color.text.blueDark};
`;

const Subtitle = styled.p`
  margin: 0;
  ${body3}
`;

const StampWrapper1 = styled.div`
  position: absolute;
  top: -30px;
  left: 109px;
  ${mediaQueries.md()} {
    top: -45px;
  }
`;

const StampWrapper2 = styled.div`
  position: absolute;
  right: 15px;
  top: -18px;
  ${mediaQueries.md()} {
    top: -38px;
    right: 23px;
  }
`;

const StampWrapper3 = styled.div`
  position: absolute;
  ${mediaQueries.md()} {
    top: 180px;
    left: -15px;
  }
`;

const StampWrapper4 = styled.div`
  position: absolute;
  right: 10px;
  top: -10px;
  ${mediaQueries.md()} {
    bottom: -25px;
    right: 60px;
    top: auto;
  }
`;

const StampVariant = ({ index, image }: { index: number; image: string }) => {
  switch (index % 4) {
    case 0:
      return (
        <StampWrapper1>
          <SmallDestinationStampVariant variant="1" image={image} />
        </StampWrapper1>
      );
    case 1:
      return (
        <StampWrapper2>
          <SmallDestinationStampVariant variant="2" image={image} />
        </StampWrapper2>
      );
    case 2:
      return (
        <StampWrapper3>
          <SmallDestinationStampVariant variant="3" image={image} />
        </StampWrapper3>
      );
    case 3:
      return (
        <StampWrapper4>
          <SmallDestinationStampVariant variant="4" image={image} />
        </StampWrapper4>
      );
  }
};

export default function Destinations({
  destinations,
}: {
  destinations: DestinationPage[] | null;
}) {
  return (
    <Container id="destinations">
      <Header>
        <Title>
          <Trans>Our destinations</Trans>
        </Title>
        <Subtitle>
          <Trans>
            We always sets down our suitcases where life is peaceful.
          </Trans>
        </Subtitle>
      </Header>
      <Content>
        {destinations === null ? (
          <Loader />
        ) : (
          <>
            {destinations.map((destination, index) => (
              <DestinationCard
                key={index}
                title={destination.destination.name}
                subtitle={destination.introduction}
                background={`${destination.photo.url}?w=768`}
                pending={destination.visibility === ResourceVisibility.PRIVATE}
                slug={destination.seoSlug}
              >
                <StampVariant
                  index={index}
                  image={destination.menuDrawing.url}
                />
              </DestinationCard>
            ))}
            <DestinationCard
              title={`Poitiers`}
              subtitle={t`Green and historic city, young and lively`}
              background={PhotoPoitiers.src}
              pending={true}
              slug={`poitiers`}
            >
              <StampVariant
                index={destinations.length}
                image={PoitiersMonument.src}
              />
            </DestinationCard>
          </>
        )}
      </Content>
    </Container>
  );
}

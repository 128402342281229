import Image from "next/image";
import styled from "styled-components";
import { HiddenOnMobile } from "~/components/Breakpoint";
import SmallDestinationStampVariant from "~/components/SmallDestinationStamp";
import { Flex, Stack } from "~/guidelines/Flex";
import { Star } from "~/guidelines/Icon";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import JeanAide from "~/guidelines/Drawings/JeanAide.svg";

const Card = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(7)};
  align-items: center;
`;

const Stars = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(2)};
`;

const Text = styled.p`
  margin: 0;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  margin: 0;
  color: ${({ theme }) => theme.color.text.blueDark};
  ${mediaQueries.md()} {
    font-size: 20px;
  }
`;

const Footer = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(6)};
  align-items: center;
`;

const Guest = styled(Stack)`
  border-right: 1px solid ${({ theme }) => theme.color.text.blueDark};
  padding-right: 24px;
`;

const Name = styled.div`
  color: ${({ theme }) => theme.color.text.blueDark};
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
`;

const Destination = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(6)};
  align-items: center;
`;

const CircleImg = styled(Image)`
  border-radius: 50%;
`;

export default function TestimonialCard({
  text,
  guest,
  loge,
  destinationImage,
  source,
  nbStars,
}: {
  text: string;
  guest: string;
  loge: string;
  destinationImage: string;
  source: string;
  nbStars: number;
}) {
  return (
    <Card>
      <Stars>
        {Array.from({ length: nbStars }, (_, index) => (
          <Star key={index} />
        ))}
      </Stars>
      <Text>{text}</Text>
      <Footer>
        <CircleImg src={JeanAide} alt="Jean Aide" height="56" />
        <Guest>
          <Name>{guest}</Name>
          <Name>{source}</Name>
        </Guest>
        <Destination>
          <Name>{loge}</Name>
          <HiddenOnMobile>
            <SmallDestinationStampVariant
              image={destinationImage}
              variant="1"
            />
          </HiddenOnMobile>
        </Destination>
      </Footer>
    </Card>
  );
}

import { motion, useAnimation } from "framer-motion";
import React, { ReactNode, RefObject, useEffect } from "react";
import { useMeasure } from "react-use";
import styled from "styled-components";
import usePrevious from "~/core/usePrevious";

const WillGrow = styled(motion.div)`
  will-change: transform, opacity, height;
  overflow: hidden;
`;

const Content = styled.div`
  width: max-content;
  height: max-content;
`;

type Width = "width";

type Height = "height";

export type GrowDirection = Width | Height;

type SpringGrow =
  | {
      direction: Width;
      width: number;
    }
  | {
      direction: Height;
      height: number;
    };

const Grow = ({
  direction,
  isVisible,
  children,
}: {
  direction: GrowDirection;
  isVisible: boolean;
  children: ReactNode;
}) => {
  const previous = usePrevious(isVisible);
  const [ref, rect] = useMeasure() as unknown as [
    RefObject<HTMLDivElement>,
    Pick<DOMRectReadOnly, "height" | "width">,
  ];
  const grow = {
    direction,
    [direction === "height" ? "height" : "width"]: isVisible
      ? rect[direction]
      : 0,
  } as SpringGrow;

  const controls = useAnimation();

  const variants = {
    visible: { [direction]: isVisible ? rect[direction] : 0 },
    hidden: { [direction]: 0 },
  };

  const style =
    grow.direction === "height"
      ? {
          height: isVisible && previous === isVisible ? "auto" : grow.height,
        }
      : { width: isVisible && previous === isVisible ? "auto" : grow.width };

  useEffect(() => {
    controls.start(isVisible ? "visible" : "hidden");
  }, [controls, isVisible]);

  return (
    <WillGrow animate={controls} variants={variants} style={style}>
      <Content ref={ref}>{children}</Content>
    </WillGrow>
  );
};

export default Grow;

import { t } from "@lingui/macro";
import React, {
  ButtonHTMLAttributes,
  MutableRefObject,
  ReactNode,
} from "react";
import { DestinationMenuEntry } from "~/applications/OfferSearch/Domain/DestinationPage";
import Menu, {
  useMenuState,
} from "~/applications/OfferSearch/Ui/StayRequestForm/Menu";
import DestinationSelector from "~/applications/OfferSearch/Ui/StayRequestForm/Selector/DestinationSelector";

export const DestinationPicker = ({
  destinations,
  onDestinationChange,
  picker,
}: {
  destination: DestinationMenuEntry;
  destinations: Array<DestinationMenuEntry>;
  onDestinationChange: (newDestination: DestinationMenuEntry) => void;
  picker: (
    triggerButtonProps: ButtonHTMLAttributes<HTMLButtonElement>,
    triggerButtonRef: MutableRefObject<HTMLButtonElement>,
  ) => ReactNode;
}) => {
  const state = useMenuState();

  return (
    <Menu
      state={state}
      title={t`Select a destination`}
      triggerButtonRender={picker}
      footer={undefined}
    >
      <DestinationSelector
        destinations={destinations}
        selectedDestination={undefined}
        onChange={onDestinationChange}
      />
    </Menu>
  );
};

export default DestinationPicker;

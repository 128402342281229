import React, { ButtonHTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
import { KeyboardArrowDown } from "~/guidelines/Icon";
import { buttonLarge } from "~/guidelines/Typography";

const ButtonTrigger = styled.button`
  appearance: none;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  ${buttonLarge};
  color: ${({ theme }) => theme.color.brand.blueDark};
  cursor: pointer;
`;

export type TextualTriggerProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode;
};

const TextualTrigger = React.forwardRef<HTMLButtonElement, TextualTriggerProps>(
  function TextualTriggerForwardRef({ children, ...ariaButtonProps }, ref) {
    return (
      <ButtonTrigger {...ariaButtonProps} ref={ref}>
        {children} <KeyboardArrowDown aria-hidden="true" />
      </ButtonTrigger>
    );
  },
);

export default TextualTrigger;

import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const ArrowForward = ({ color }: IconSvgProps) => {
  return (
    <IconSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M5.43677 13H16.6068L11.7268 17.88C11.3368 18.27 11.3368 18.91 11.7268 19.3C12.1168 19.69 12.7468 19.69 13.1368 19.3L19.7268 12.71C20.1168 12.32 20.1168 11.69 19.7268 11.3L13.1468 4.70002C12.9599 4.51276 12.7063 4.40753 12.4418 4.40753C12.1772 4.40753 11.9236 4.51276 11.7368 4.70002C11.3468 5.09002 11.3468 5.72002 11.7368 6.11002L16.6068 11H5.43677C4.88677 11 4.43677 11.45 4.43677 12C4.43677 12.55 4.88677 13 5.43677 13Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

export default ArrowForward;

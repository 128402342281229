import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const KeyboardArrowUp = ({ color }: IconSvgProps) => {
  return (
    <IconSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M8.35303 15L12.233 11.12L16.113 15C16.503 15.39 17.133 15.39 17.523 15C17.913 14.61 17.913 13.98 17.523 13.59L12.933 8.99999C12.7462 8.81274 12.4926 8.7075 12.228 8.7075C11.9635 8.7075 11.7099 8.81274 11.523 8.99999L6.93303 13.59C6.54303 13.98 6.54303 14.61 6.93303 15C7.32303 15.38 7.96303 15.39 8.35303 15Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

export default KeyboardArrowUp;

import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { BrandIdentity } from "~/applications/Editorials/Domain/BrandIdentity";
import { contentStudioClient } from "~/clients/ContentStudio";

const BrandIdentitySchema = z.object({
  pressKitUrl: z.string().nullable(),
});

export async function fetchBrandIdentity(): Promise<BrandIdentity> {
  const payload = await contentStudioClient.fetch(`
    *[_type == 'brandIdentity' && _id == "defaultBrandIdentity"][0]
    {
      pressKitUrl
    }
  `);

  const brandIdentityPayload = BrandIdentitySchema.parse(payload);

  return {
    pressKitUrl: brandIdentityPayload.pressKitUrl ?? null,
  };
}

export const useBrandIdentity = () => {
  const { data: brandIdentity = null } = useQuery(["brand-identity"], () =>
    fetchBrandIdentity(),
  );

  return { brandIdentity };
};

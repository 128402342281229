import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const Search = ({ color }: IconSvgProps) => {
  return (
    <IconSvg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4146 11.4716H13.2046L17.4446 15.7316C17.8546 16.1416 17.8546 16.8116 17.4446 17.2216C17.0346 17.6316 16.3646 17.6316 15.9546 17.2216L11.7046 12.9716V12.1816L11.4346 11.9016C10.0346 13.1016 8.12461 13.7216 6.0946 13.3816C3.3146 12.9116 1.09461 10.5916 0.754605 7.79156C0.234605 3.56156 3.79461 0.00155762 8.02461 0.521558C10.8246 0.861558 13.1446 3.08156 13.6146 5.86156C13.9546 7.89156 13.3346 9.80156 12.1346 11.2016L12.4146 11.4716ZM2.70461 6.97156C2.70461 9.46156 4.71461 11.4716 7.20461 11.4716C9.6946 11.4716 11.7046 9.46156 11.7046 6.97156C11.7046 4.48156 9.6946 2.47156 7.20461 2.47156C4.71461 2.47156 2.70461 4.48156 2.70461 6.97156Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

export default Search;

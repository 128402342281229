import { IPlayerProps } from "@lottiefiles/react-lottie-player";
import { AnimationItem } from "lottie-web";
import dynamic from "next/dynamic";
import React, { useEffect, useState } from "react";

const PlayerDynamic = dynamic<IPlayerProps>(
  () =>
    import("@lottiefiles/react-lottie-player").then((result) => result.Player),
  { ssr: false },
);

export type Lottie = string | Record<string, unknown> | undefined;

const useLazyLoadLottie = ({
  lottieLoader,
}: {
  lottieLoader: () => Promise<Lottie>;
}) => {
  const [lottie, setLottie] = useState<Lottie>(undefined);

  useEffect(() => {
    lottieLoader().then((lottie: Lottie) => setLottie(lottie));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { lottie };
};

type LottiePlayerProps = {
  /**
   * This component will lazy load the lottie file
   * @param lottieLoader - function that returns a promise that resolves to a lottie file
   * @example
   * ```ts
   * lottieLoader: () => import("./path/to/lottie.lottie.json")
   * ```
   * @example
   * ```ts
   * lottieLoader: () => import("./path/to/lottie.lottie.json").then((result) => result.default)
   * ```
   * @returns Promise<Lottie>
   */
  lottieLoader: () => Promise<Lottie>;
  autoplay?: boolean;
  loop?: boolean;
  keepLastFrame?: boolean;
  play: boolean;
  style?: {
    [key: string]: string | number;
  };
};

const LottiePlayer = ({
  lottieLoader,
  autoplay = false,
  loop = false,
  keepLastFrame = false,
  play,
  style,
}: LottiePlayerProps) => {
  const [lottieRef, setLottieRef] = useState<AnimationItem | null>(null);

  useEffect(() => {
    if (lottieRef && play) {
      lottieRef.play();
    }
  }, [lottieRef, play]);

  const { lottie } = useLazyLoadLottie({ lottieLoader });

  if (lottie === undefined) {
    return <></>;
  }

  return (
    <PlayerDynamic
      lottieRef={(ref) => {
        setLottieRef(ref);
      }}
      src={lottie}
      style={style}
      loop={loop}
      autoplay={autoplay}
      keepLastFrame={keepLastFrame}
    />
  );
};

export default LottiePlayer;

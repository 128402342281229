import { Trans } from "@lingui/macro";
import styled from "styled-components";
import { destinationHomepageRoute, offersRoute } from "~/core/routes";
import { Button } from "~/guidelines/Button";
import { Flex } from "~/guidelines/Flex";
import { KeyboardArrowRight } from "~/guidelines/Icon";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import theme from "~/guidelines/Theme/theme";

const Container = styled(Flex)<{ $image: string }>`
  position: relative;
  height: 420px;
  align-self: stretch;
  border-radius: 8px;
  background:
    linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.42) 35.17%,
      rgba(0, 0, 0, 0.81) 100%
    ),
    linear-gradient(
      0deg,
      rgba(16, 6, 159, 0.12) 0%,
      rgba(16, 6, 159, 0.12) 100%
    ),
    url(${({ $image }) => $image}) 50% 50% / cover no-repeat;
  ${mediaQueries.md()} {
    height: auto;
  }
`;

const Content = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  gap: ${({ theme }) => theme.spacing(6)};
  padding: ${({ theme }) => theme.spacing(8)}};
  border-radius: ${({ theme }) => theme.borderRadius.large};
`;

const Card = styled(Flex)`
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: flex-end;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(6)};
`;

const Header = styled(Flex)`
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  align-self: stretch;
  justify-content: flex-end;
  align-items: flex-start;
`;

const Title = styled.h3`
  margin: 0;
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;

  ${mediaQueries.md()} {
    font-size: 28px;
  }

  color: ${({ theme }) => theme.color.text.white};
`;

const Subtitle = styled.p`
  margin: 0;
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.text.white};
`;

const Actions = styled(Flex)`
  align-self: stretch;
  gap: ${({ theme }) => theme.spacing(5)};
`;

export default function DestinationCard({
  children,
  title,
  subtitle,
  background,
  slug,
  pending = false,
}: {
  children: React.ReactNode;
  title: string;
  subtitle: string;
  background: string;
  slug: string;
  pending?: boolean;
}) {
  return (
    <Container $image={background}>
      {children}
      <Content>
        <Card>
          <Header>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </Header>
          <Actions>
            {pending ? (
              <Button size="large" kind="primary" isDisabled>
                <Trans>We&apos;re coming</Trans>
              </Button>
            ) : (
              <>
                <Button
                  size="large"
                  kind="primary"
                  href={offersRoute(slug, undefined, undefined)}
                >
                  <Trans>Book</Trans>
                </Button>
                <Button
                  size="large"
                  kind="quaternary"
                  variant="dark_background"
                  trailingIcon={<KeyboardArrowRight />}
                  iconColor={theme.color.text.white}
                  href={destinationHomepageRoute(slug)}
                >
                  <Trans>For more information</Trans>
                </Button>
              </>
            )}
          </Actions>
        </Card>
      </Content>
    </Container>
  );
}

import { Trans } from "@lingui/macro";
import { useRouter } from "next/navigation";
import styled from "styled-components";
import { DestinationPage } from "~/applications/OfferSearch/Domain/DestinationPage";
import { AVAILABLE_LOCALES } from "~/core/locale";
import {
  destinationHomepageRoute,
  gogailleLegalMentionsRoute,
  gogaillePrivacyCookiesRoute,
  gogaillePrivacyPolicyRoute,
} from "~/core/routes";
import { Button } from "~/guidelines/Button";
import { Flex, Stack } from "~/guidelines/Flex";
import Link from "~/guidelines/Link";
import LocaleSwitcher from "~/guidelines/LocaleSwitcher";
import GogailleLogo, { Svg } from "~/guidelines/Logo/GogailleLogo";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import theme from "~/guidelines/Theme/theme";

const LogoBlock = styled.div`
  & ${Svg} {
      height: 57.6144px;
      width: 41.277px;
    }
  }
`;

const Container = styled(Stack)`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing(17, 7)};
  align-items: center;
  gap: ${({ theme }) => theme.spacing(11)};
  margin-top: 175px;
  ${mediaQueries.md()} {
    margin-top: 64px;
    padding: ${({ theme }) => theme.spacing(13)} 0;
  }
`;

const Content = styled(Flex)`
  max-width: 1280px;
  width: 100%;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(7)};
  align-items: flex-start;
  ${mediaQueries.md()} {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing(0)};
    justify-content: space-between;
  }
`;

const DestinationColumn = styled(Stack)`
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(7)};
`;

const Links = styled(Flex)`
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(7)};
`;

const MoreColumn = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(8)};
  align-items: flex-start;
  ${mediaQueries.md()} {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const StackLinks = styled(Stack)`
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(3)};
`;

const Credits = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(7)};
  border-top: 1px solid ${({ theme }) => theme.color.background.white};
  max-width: 1280px;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing(7)};
  justify-content: space-between;
  align-items: flex-start;
  ${mediaQueries.md()} {
    flex-direction: row;
  }
`;

const CreditsLinks = styled(Stack)`
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(6)};
  ${mediaQueries.md()} {
    flex-direction: row;
  }
`;

const Text = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.color.background.white};
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const StackEndColumn = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(5)};
  ${mediaQueries.md()} {
    align-items: flex-end;
  }
`;

export default function Footer({
  destinations,
  currentLocale,
  pressKitUrl,
}: {
  destinations: DestinationPage[] | null;
  currentLocale: AVAILABLE_LOCALES;
  pressKitUrl: string | null;
}) {
  const router = useRouter();
  return (
    <Container>
      <Content>
        <DestinationColumn>
          <LogoBlock>
            <GogailleLogo color={theme.color.background.white} />
          </LogoBlock>
          <Links>
            {destinations?.map((destination, index) => (
              <Button
                key={index}
                href={destinationHomepageRoute(destination.seoSlug)}
                kind="quaternary"
                variant="dark_background"
              >
                {destination.destination.name}
              </Button>
            ))}
          </Links>
        </DestinationColumn>
        <MoreColumn>
          <StackLinks>
            <Button
              href="mailto:bonjour@gogaille.fr"
              rel="external"
              kind="quaternary"
              variant="dark_background"
            >
              <Trans>Contact us</Trans>
            </Button>
            <Button
              href="https://gogaille.flatchr.io/"
              rel="external"
              kind="quaternary"
              variant="dark_background"
            >
              <Trans>Careers</Trans>
            </Button>
            {pressKitUrl !== null && (
              <Button
                href={pressKitUrl}
                rel="external"
                kind="quaternary"
                variant="dark_background"
              >
                <Trans>Press kit</Trans>
              </Button>
            )}
          </StackLinks>
          <StackEndColumn>
            <LocaleSwitcher
              currentLocale={currentLocale}
              availableLocales={Object.values(AVAILABLE_LOCALES)}
              onLocaleChange={(newLocale) => {
                router.push(`/${newLocale}`);
              }}
            />
          </StackEndColumn>
        </MoreColumn>
      </Content>
      <Credits>
        <CreditsLinks>
          <Link href={gogailleLegalMentionsRoute()} kind="tertiary">
            <Text>
              <Trans>Legal information</Trans>
            </Text>
          </Link>
          <Link href={gogaillePrivacyPolicyRoute()} kind="tertiary">
            <Text>
              <Trans>Privacy Policy</Trans>
            </Text>
          </Link>
          <Link href={gogaillePrivacyCookiesRoute()} kind="tertiary">
            <Text>
              <Trans>Cookie Usage Policy</Trans>
            </Text>
          </Link>
        </CreditsLinks>
        <Text>
          <Trans>
            {"\u00a9"} {new Date().getUTCFullYear()} Gogaille. All right
            reserved.
          </Trans>
        </Text>
      </Credits>
    </Container>
  );
}

import React from "react";
import styled from "styled-components";

export const Svg = styled.svg`
  color: ${({ color }) => color ?? `inherit`};
  flex-shrink: 0;
  transition: color 0.2s ease;
  vertical-align: middle;
`;

const GogailleLogo = ({ color }: { color?: string }) => {
  return (
    <Svg
      width="25"
      height="34"
      viewBox="0 0 25 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M12.4558 32.5217C12.4558 33.0037 12.216 33.2254 11.832 33.2254C11.4275 33.2254 11.2845 32.9432 11.2845 32.5217V31.7718C11.2845 30.7958 12.4558 30.6147 12.4558 30.6147V32.5217ZM13.2906 32.8946V28.5912C13.2906 27.6393 12.8539 27.1421 11.9925 27.1421C11.1001 27.1421 10.6282 27.6547 10.6282 28.6245V29.0766L11.4669 28.9473V28.6168C11.4669 28.1189 11.622 27.8969 11.9698 27.8969C12.3179 27.8969 12.4412 28.0989 12.4412 28.6688V29.8641C12.4412 29.8641 10.4624 30.0511 10.4624 31.7148L10.454 32.3747C10.454 33.4076 10.8841 34 11.6337 34C12.0133 34 12.2965 33.8766 12.479 33.6326C12.4884 33.709 12.5002 33.7872 12.5132 33.8512L12.5262 33.9131H13.3203L13.312 33.8279C13.2987 33.6924 13.2906 33.3457 13.2906 32.8946Z"
        fill="currentColor"
      />
      <path
        d="M1.33853 30.9313H2.0687V31.9012C2.0687 32.8746 1.9043 33.2195 1.44015 33.2195C1.19492 33.2195 0.868875 33.1176 0.868875 32.2361V28.8476C0.868875 28.0029 1.17678 27.889 1.47781 27.889C1.96159 27.889 2.06056 28.2539 2.06056 28.8022V29.1809L2.91502 29.0522V28.818C2.91502 28.0785 2.76671 27.0954 1.48331 27.0954C0.512909 27.0954 0 27.7232 0 28.911V32.2003C0 32.9728 0.133205 34 1.28546 34C1.69214 34 2.00014 33.8517 2.19112 33.5676C2.19906 33.6801 2.20907 33.7902 2.21927 33.8482L2.23055 33.9123H2.92865V30.164H1.33853V30.9313Z"
        fill="currentColor"
      />
      <path
        d="M5.68649 32.2234C5.68649 33.0935 5.36741 33.2108 5.05529 33.2108C4.62987 33.2108 4.4231 32.8982 4.4231 32.2547V28.8431C4.4231 27.9975 4.75808 27.889 5.04989 27.889C5.32317 27.889 5.68649 27.988 5.68649 28.844V32.2234ZM5.06971 27.0954C4.09244 27.0954 3.55423 27.7278 3.55423 28.8761V32.1974C3.55423 33.4104 4.03437 34 5.02223 34C6.03961 34 6.55537 33.3905 6.55537 32.1886V28.8753C6.55537 27.7274 6.02775 27.0954 5.06971 27.0954Z"
        fill="currentColor"
      />
      <path
        d="M8.43744 30.9313H9.16762V31.9012C9.16762 32.8746 9.00322 33.2195 8.53907 33.2195C8.29384 33.2195 7.96779 33.1176 7.96779 32.2361V28.8476C7.96779 28.0029 8.2757 27.889 8.57673 27.889C9.06051 27.889 9.15948 28.2539 9.15948 28.8022V29.1809L10.014 29.0522V28.818C10.014 28.0785 9.86562 27.0954 8.58222 27.0954C7.61183 27.0954 7.09892 27.7232 7.09892 28.911V32.2003C7.09892 32.9728 7.23212 34 8.38438 34C8.79106 34 9.09906 33.8517 9.29004 33.5676C9.29798 33.6801 9.30799 33.7902 9.31819 33.8482L9.32947 33.9123H10.0276V30.164H8.43744V30.9313Z"
        fill="currentColor"
      />
      <path
        d="M13.9497 33.9128H14.8043V27.1835H13.9497V33.9128Z"
        fill="currentColor"
      />
      <path
        d="M16.4773 27.1835H15.6227V33.9128H17.6915L17.7886 33.128H16.4773V27.1835Z"
        fill="currentColor"
      />
      <path
        d="M18.9211 27.1835H18.0666V33.9128H20.1353L20.2324 33.128H18.9211V27.1835Z"
        fill="currentColor"
      />
      <path
        d="M22.2131 29.7633L21.1934 29.992V28.7948C21.1934 28.1955 21.3488 27.9398 21.7134 27.9398C22.0683 27.9398 22.2131 28.1859 22.2131 28.7894V29.7633ZM21.7188 27.1557C20.8217 27.1557 20.3475 27.7626 20.3475 28.9103V32.2886C20.3475 33.081 20.5021 33.9906 21.7072 33.9906C22.5854 33.9906 23.0309 33.4682 23.0309 32.4383V32.0869L22.1896 32.3455V32.4438C22.1896 33.0113 22.0535 33.2323 21.7043 33.2323C21.3574 33.2323 21.1888 32.9249 21.1888 32.2925V30.8023L23.0452 30.4068V28.8321C23.0452 27.7198 22.599 27.1557 21.7188 27.1557Z"
        fill="currentColor"
      />
      <path
        d="M23.8705 27.8725H23.6311V27.6077H23.8705C23.9195 27.6077 23.956 27.6197 23.9798 27.6435C24.0037 27.6674 24.0156 27.6999 24.0156 27.7415C24.0156 27.7756 24.0026 27.8089 23.9793 27.8311C23.9449 27.8639 23.9054 27.8725 23.8705 27.8725ZM24.0685 28.095C24.0679 28.0787 24.0673 28.0648 24.0665 28.0534C24.0634 28.0097 24.0558 27.9754 24.0388 27.9517C24.0202 27.9261 23.9899 27.9065 23.9487 27.9018V27.8997C24.0212 27.8869 24.0798 27.8282 24.0798 27.7283C24.0798 27.6689 24.0598 27.6287 24.019 27.595C23.9935 27.5739 23.9414 27.5529 23.8753 27.5529H23.567V28.226H23.6311V27.9272H23.8743C23.9044 27.9259 23.9282 27.9307 23.9455 27.9423C23.9851 27.9688 23.9989 28.0114 24.003 28.0502C24.0053 28.0737 24.0079 28.0975 24.009 28.1214C24.0114 28.1662 24.0161 28.211 24.027 28.226H24.0975C24.084 28.2103 24.0713 28.1729 24.0685 28.095Z"
        fill="currentColor"
      />
      <path
        d="M23.807 28.3923C23.529 28.3923 23.3027 28.166 23.3027 27.8878C23.3027 27.6098 23.529 27.3836 23.807 27.3836C24.0851 27.3836 24.3114 27.6098 24.3114 27.8878C24.3114 28.166 24.0851 28.3923 23.807 28.3923ZM23.807 27.3355C23.5025 27.3355 23.2546 27.5833 23.2546 27.8878C23.2546 28.1926 23.5025 28.4403 23.807 28.4403C24.1116 28.4403 24.3595 28.1926 24.3595 27.8878C24.3595 27.5833 24.1116 27.3355 23.807 27.3355Z"
        fill="currentColor"
      />
      <path
        d="M12.952 24.5746C17.5487 24.5746 20.6993 22.9602 20.6993 22.9602V20.669C19.3911 21.2604 15.6324 22.3234 12.7544 22.3234C8.49754 22.3234 6.4974 21.6697 4.32825 20.2389C6.30946 22.9491 9.36994 24.5746 12.952 24.5746Z"
        fill="currentColor"
      />
      <path
        d="M4.32613 20.2426C2.80879 18.1669 1.92422 15.4621 1.92422 12.3101C1.92422 9.24924 2.81026 6.29842 4.2198 4.28249C3.09619 5.13008 2.01848 6.33432 1.26231 7.54661C0.28387 9.11103 0 10.8641 0 12.5713C0 14.9272 0.826598 16.9815 2.5668 18.7841C3.09697 19.3332 3.68384 19.8189 4.32613 20.2426Z"
        fill="currentColor"
      />
      <path
        d="M20.9642 14.1331C20.7879 14.4336 20.6998 15.0702 20.6998 16.043V20.6714C21.538 20.2643 22.32 19.8571 23.0465 19.4569V13.2276C22.4751 13.2585 22.0535 13.3312 21.7831 13.4464C21.4136 13.6037 21.1406 13.8327 20.9642 14.1331Z"
        fill="currentColor"
      />
      <path
        d="M18.3391 3.08962C18.6573 3.08962 18.9532 3.01085 19.226 2.85332C19.4989 2.69589 19.7489 2.39799 19.9765 1.95982C19.9765 1.95982 20.1187 1.66673 20.1424 1.566C19.743 1.41886 17.6531 0.619335 15.8698 0.255228C14.9494 0.0673871 13.8451 0 12.8304 0C9.24867 0 6.17808 1.48272 4.221 4.28199C5.31332 3.62685 7.07707 2.05487 12.2075 2.00848C13.4497 1.99729 14.5855 2.21947 15.5828 2.46557C16.8481 2.77769 17.9837 3.08962 18.3391 3.08962Z"
        fill="currentColor"
      />
      <path
        d="M12.5307 3.10506C9.4233 3.10506 7.34411 4.08291 5.80871 6.03793C4.51668 7.68299 3.88067 9.65291 3.88067 11.9323C3.88067 18.0365 8.27576 21.4177 12.7696 21.4177C14.7553 21.4177 16.4925 20.8835 17.5468 20.3447V16.049C17.5468 15.0762 17.4584 14.4395 17.2822 14.1391C17.1059 13.8386 16.8328 13.6097 16.4634 13.4523C16.0936 13.295 15.4424 13.2163 14.5098 13.2163V12.3635H20.6974V10.2899H12.4568V15.0869H15.3267V18.3922C14.7191 18.6218 14.1502 18.7385 13.1822 18.7385C9.80065 18.7385 7.66927 15.9309 7.66927 12.3506C7.66927 8.77021 9.93808 5.82753 12.9252 5.83067C15.85 5.83381 17.2624 7.16743 18.2159 8.63631H21.8137V5.32953C21.8137 5.32953 21.2637 4.92011 20.6067 4.53599V7.472H19.9755C18.4619 4.5305 15.5293 3.10506 12.5307 3.10506Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default GogailleLogo;

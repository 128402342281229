import { Trans, t } from "@lingui/macro";
import Image from "next/image";
import styled from "styled-components";
import { Button } from "~/guidelines/Button";
import { Flex } from "~/guidelines/Flex";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { EyeCatchBox } from "./EyeCatchBox";
import JeanCherche from "~/guidelines/Drawings/JeanCherche.svg";

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 5)};
`;

const ImgFixedHeight = styled(Image)`
  height: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.color.text.white};
`;

const TextDarkBackground = styled.p`
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  margin: 0;
  color: ${({ theme }) => theme.color.text.white};
  ${mediaQueries.md()} {
    font-size: 18px;
  }
`;

const Actions = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(6)};
`;

export default function CtaOffers({ onClickCta }: { onClickCta: () => void }) {
  return (
    <Container>
      <EyeCatchBox title={t`Current offers at Gogaille`}>
        <ImgFixedHeight width={202} height={100} src={JeanCherche} alt="" />
        <TextDarkBackground>
          {t`You're come a long way to read this and take advantage of our offers. What a lynx eye.`}
        </TextDarkBackground>
        <Actions>
          <Button size="large" kind="primary" onPress={onClickCta}>
            <Trans>Book</Trans>
          </Button>
          <Button
            size="large"
            kind="tertiary"
            variant="dark_background"
            href="#destinations"
          >
            <Trans>Discover</Trans>
          </Button>
        </Actions>
      </EyeCatchBox>
    </Container>
  );
}

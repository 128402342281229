import { Trans, t } from "@lingui/macro";
import Image from "next/image";
import styled from "styled-components";
import Video from "~/components/Video";
import { Button } from "~/guidelines/Button";
import { Flex } from "~/guidelines/Flex";
import { Search } from "~/guidelines/Icon";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import theme from "~/guidelines/Theme/theme";
import { Ellipse } from "./Ellipse";
import HomePagePoster from "~/applications/Editorials/Ui/Home/HomePagePoster.jpg";
import ManReview from "~/applications/Editorials/Ui/Home/ManReview.svg";
import WomanReview from "~/applications/Editorials/Ui/Home/WomanReview.svg";

const Wrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(13, 5)};
  flex-direction: column;
  align-items: center;
  gap: 160px;
  margin-top: 100px;
  text-align: center;
  width: 100%;
  ${mediaQueries.md()} {
    padding: ${({ theme }) => theme.spacing(11, 13)};
  }
`;

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(10)};
  position: relative;
  z-index: 10;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(6)};
`;

const Title = styled.header`
  font-family: "Bely Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 120%;

  ${mediaQueries.md()} {
    font-size: 64px;
  }

  color: ${({ theme }) => theme.color.text.blueDark};
`;

const TopLine = styled.span`
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  display: block;

  ${mediaQueries.md()} {
    font-size: 40px;
  }

  color: ${({ theme }) => theme.color.text.blueDark};
`;

const CatchEye = styled.span`
  color: ${({ theme }) => theme.color.brand.blue};
`;

const CatchLine = styled.header`
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  ${mediaQueries.md()} {
    font-size: 18px;
  }
  color: ${({ theme }) => theme.color.text.greyDark};
`;

const Reviews = styled.div`
  background-color: ${({ theme }) => theme.color.background.creamDark};
  width: 100%;
  padding-top: 150px;
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(5)};
  ${mediaQueries.md()} {
    gap: ${({ theme }) => theme.spacing(9)};
  }
`;

const ReviewGroup = styled(Flex)<{ $paddingLeft?: string }>`
  transform: rotate(-11deg);
  gap: ${({ theme }) => theme.spacing(4)};
  align-items: center;
  padding-left: ${({ $paddingLeft }) => $paddingLeft ?? "0"};

  ${mediaQueries.md()} {
    gap: ${({ theme }) => theme.spacing(13)};
  }
  & > img {
    height: 40px;

    ${mediaQueries.md()} {
      height: auto;
    }
  }
`;

const Sentence = styled.p`
  text-align: center;
  font-family: "Bely Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.text.greyMouse};
  white-space: nowrap;
  margin: 0;
  ${mediaQueries.md()} {
    font-size: 64px;
  }
`;

export default function Hero({ onClickCta }: { onClickCta: () => void }) {
  return (
    <>
      <Wrapper>
        <HeroContainer>
          <Header>
            <Title>
              <TopLine>{t`You are not in a hotel.`}</TopLine>
              <Trans>
                You are with <CatchEye>Gogaille.</CatchEye>
              </Trans>
            </Title>
            <CatchLine>
              <Trans>Charming rooms with autonomous access.</Trans>
            </CatchLine>
          </Header>
          <Button
            onPress={onClickCta}
            kind="primary"
            size="normal"
            trailingIcon={<Search />}
          >
            <Trans>Book a room</Trans>
          </Button>
        </HeroContainer>
        <Video
          autoPlay={true}
          poster={HomePagePoster.src}
          sources={[
            { src: "/assets/home/hero.webm", type: "video/webm" },
            { src: "/assets/home/hero.mp4", type: "video/mp4" },
          ]}
        />
      </Wrapper>
      <Ellipse
        top={true}
        bottom={true}
        color={theme.color.background.creamDark}
        topColor={theme.color.background.cream}
        marginTop="-350px"
      >
        <Reviews>
          <ReviewGroup>
            <Sentence>
              {"“"}
              {t`review_hero_01`}
              {"“"}
            </Sentence>
            <Sentence>
              {"“"}
              {t`review_hero_02`}
              {"“"}
            </Sentence>
            <Sentence>
              {"“"}
              {t`review_hero_03`}
              {"“"}
            </Sentence>
          </ReviewGroup>
          <ReviewGroup>
            <Sentence>
              {"“"}
              {t`review_hero_04`}
              {"“"}
            </Sentence>
            <Image src={ManReview} alt="" />
            <Sentence>
              {"“"}
              {t`review_hero_05`}
              {"“"}
            </Sentence>
            <Sentence>
              {"“"}
              {t`review_hero_06`}
              {"“"}
            </Sentence>
          </ReviewGroup>
          <ReviewGroup>
            <Sentence>
              {"“"}
              {t`review_hero_07`}
              {"“"}
            </Sentence>
            <Image src={WomanReview} alt="" />
            <Sentence>
              {"“"}
              {t`review_hero_08`}
              {"“"}
            </Sentence>
            <Sentence>
              {"“"}
              {t`review_hero_09`}
              {"“"}
            </Sentence>
          </ReviewGroup>
          <ReviewGroup>
            <Sentence>
              {"“"}
              {t`review_hero_10`}
              {"“"}
            </Sentence>
            <Image src={ManReview} alt="" />
            <Sentence>
              {"“"}
              {t`review_hero_11`}
              {"“"}
            </Sentence>
            <Sentence>
              {"“"}
              {t`review_hero_12`}
              {"“"}
            </Sentence>
          </ReviewGroup>
          <ReviewGroup $paddingLeft={"30%"}>
            <Sentence>
              {"“"}
              {t`review_hero_13`}
              {"“"}
            </Sentence>
            <Sentence>
              {"“"}
              {t`review_hero_03`}
              {"“"}
            </Sentence>
            <Sentence>
              {"“"}
              {t`review_hero_01`}
              {"“"}
            </Sentence>
          </ReviewGroup>
        </Reviews>
      </Ellipse>
    </>
  );
}

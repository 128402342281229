import styled from "styled-components";
import { Flex, Stack } from "~/guidelines/Flex";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import GogailleLogo90 from "~/guidelines/Logo/Gogaille-background-img.svg";

const Wrapper = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(7)};
  align-items: center;
  gap: ${({ theme }) => theme.spacing(13)};
  max-width: 1040px;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  background-color: ${({ theme }) => theme.color.brand.blueDark};
  background-image: url(${GogailleLogo90.src});
  background-repeat: no-repeat;
  position: relative;
  z-index: 100;
  background-position: right -190px;
  ${mediaQueries.md()} {
    padding: ${({ theme }) => theme.spacing(11, 18)};
    background-size: 100%;
    background-position: center -190px;
  }
`;

const Container = styled(Flex)`
  justify-content: space-between;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(9)};
  flex-direction: column;
  ${mediaQueries.md()} {
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(18)};
  }
`;

const Title = styled.h2`
  font-family: "Bely Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  text-align: left;
  margin: 0;

  ${mediaQueries.md()} {
    font-size: 48px;
  }

  color: ${({ theme }) => theme.color.text.white};
  flex: 1 0 0;
`;

const Content = styled(Stack)`
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(6)};
  flex: 1 0 0;
`;

type Props = {
  children: React.ReactNode;
  title: string;
};

export function EyeCatchBox({ children, title }: Props) {
  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        <Content>{children}</Content>
      </Container>
    </Wrapper>
  );
}

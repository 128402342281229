"use client";

import { Trans, t } from "@lingui/macro";
import { useThrottleCallback } from "@react-hook/throttle";
import Link from "next/link";
import { useRouter } from "next/navigation";
import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { DestinationMenuEntry } from "~/applications/OfferSearch/Domain/DestinationPage";
import { HiddenOnMobile } from "~/components/Breakpoint";
import DestinationPicker from "~/components/Destination/DestinationPicker";
import TextualTrigger from "~/components/Trigger/TextualTrigger";
import { gogailleJourneyRoute, offersRoute } from "~/core/routes";
import { isClientSide } from "~/core/ssr";
import Grow from "~/guidelines/Animations/Grow";
import { Button } from "~/guidelines/Button";
import { Color } from "~/guidelines/Color";
import { Person } from "~/guidelines/Icon";
import GogailleLogo, { Svg } from "~/guidelines/Logo/GogailleLogo";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import theme from "~/guidelines/Theme/theme";

const padding = theme.spacing(4, 6);
const paddingMd = theme.spacing(6);
const paddingXl = theme.spacing(11);

const LogoBlock = styled.div`
  & ${Svg} {
    height: 40px;
    width: 28px;

    ${mediaQueries.md()} {
      height: 57.6144px;
      width: 41.277px;
    }
  }
`;

const StyledHeader = styled.header<{
  $backgroundKind: "transparent" | "filled";
}>`
  position: fixed;
  z-index: 1000;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: ${padding};
  ${mediaQueries.md()} {
    padding: ${paddingMd} ${paddingXl};
  }

  background-color: ${({ $backgroundKind, theme }) =>
    $backgroundKind === "filled"
      ? theme.color.background.white
      : Color.fromString(theme.color.background.white).opacity(0).rgba()};
  color: ${({ $backgroundKind, theme }) =>
    $backgroundKind === "filled"
      ? theme.color.background.blueDark
      : theme.color.background.white};
  box-shadow: ${({ $backgroundKind, theme }) =>
    $backgroundKind === "filled"
      ? theme.shadow.navigation
      : theme.shadow.navigationNone};

  &,
  & svg {
    transition:
      background-color 250ms ease,
      color 200ms ease,
      box-shadow 200ms ease;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  transition: gap 250ms ease;
  align-items: center;
`;

const ActionBlock = styled.div<{
  $backgroundKind: "transparent" | "filled";
}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > ${Content} {
    ${({ $backgroundKind }) =>
      $backgroundKind === "transparent"
        ? css`
            gap: 0px;
          `
        : css`
            gap: 16px;

            ${mediaQueries.md()} {
              gap: 32px;
            }
          `};
  }
`;

const useOnWindowScrollReachThreshold = (
  callback: (isThresholdReached: boolean) => void,
  thresholdInPixels: number,
) => {
  const scrollEventHandler = useCallback(() => {
    const y = window.scrollY || window.pageYOffset;

    const isThresholdReached = y > thresholdInPixels;

    callback(isThresholdReached);
  }, [callback, thresholdInPixels]);

  const throttledScrollEventHandler = useThrottleCallback(scrollEventHandler);

  useEffect(() => {
    window.addEventListener("scroll", throttledScrollEventHandler, {
      passive: true,
    });

    throttledScrollEventHandler();

    return () => {
      window.removeEventListener("scroll", throttledScrollEventHandler);
    };
  }, [throttledScrollEventHandler]);
};

const NewHeader = ({
  logoHref,
  destinationName,
  guestCanBookARoom,
  onBookDestination,
  destinations,
}: {
  logoHref: string;
  destinationName?: string;
  guestCanBookARoom: boolean;
  onBookDestination: () => void;
  destinations: DestinationMenuEntry[];
}) => {
  const router = useRouter();
  const animationThresholdInPixels = isClientSide()
    ? window.innerHeight * 0.17
    : 9999;

  const [backgroundKind, setBackgroundKind] = useState<
    "transparent" | "filled"
  >("transparent");

  const onScrollReachThresholdHandler = useCallback(
    (isThresholdReached: boolean) => {
      setBackgroundKind(() => {
        if (isThresholdReached) {
          return "filled";
        } else {
          return "transparent";
        }
      });
    },
    [],
  );

  useOnWindowScrollReachThreshold(
    onScrollReachThresholdHandler,
    animationThresholdInPixels,
  );

  return (
    <StyledHeader $backgroundKind={backgroundKind}>
      <LogoContainer>
        <LogoBlock>
          <Link href={logoHref} passHref aria-label="Gogaille logo">
            <GogailleLogo color={theme.color.background.blueDark} />
          </Link>
        </LogoBlock>
      </LogoContainer>
      <ActionBlock $backgroundKind="filled">
        <Content>
          <HiddenOnMobile>
            <DestinationPicker
              destinations={destinations}
              destination={destinations[0]}
              onDestinationChange={(newDestination: DestinationMenuEntry) =>
                router.push(
                  offersRoute(newDestination.seoSlug, undefined, undefined),
                )
              }
              picker={(triggerButtonProps, triggerButtonRef) => (
                <TextualTrigger {...triggerButtonProps} ref={triggerButtonRef}>
                  {destinationName ? destinationName : t`Our destinations`}
                </TextualTrigger>
              )}
            />
          </HiddenOnMobile>
          <Button
            kind="quaternary"
            size="large"
            variant={"light_background"}
            href={gogailleJourneyRoute()}
            prefetch={false}
            leadingIcon={<Person />}
            iconColor={theme.color.brand.blueDark}
          >
            <Trans>My account</Trans>
          </Button>
          <Grow direction="width" isVisible={backgroundKind !== "transparent"}>
            <Button
              kind="primary"
              onPress={onBookDestination}
              isDisabled={guestCanBookARoom === false}
              size="large"
            >
              <Trans>Book</Trans>
            </Button>
          </Grow>
        </Content>
      </ActionBlock>
    </StyledHeader>
  );
};

export default NewHeader;

import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const Loader = ({ color }: IconSvgProps) => {
  return (
    <IconSvg
      width="67"
      height="20"
      viewBox="0 0 67 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <circle cx="10" cy="10" r="10" fill="currentColor" />
      <circle cx="36" cy="10" r="9" fill="currentColor" />
      <circle cx="59.5" cy="9.5" r="7.5" fill="currentColor" />
    </IconSvg>
  );
};

export default Loader;

import styled from "styled-components";
import { Button } from "~/guidelines/Button";
import { Stack } from "~/guidelines/Flex";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { body3 } from "~/guidelines/Typography";

const Container = styled(Stack)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing(6)};
  align-self: stretch;
`;

const Header = styled(Stack)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  align-self: stretch;
`;

const Title = styled.h3`
  margin: 0;
  color: #211747;
  text-align: center;
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  ${mediaQueries.md()} {
    font-size: 32px;
  }
`;

const Subtitle = styled.p`
  margin: 0;
  ${body3}
`;

export default function CtaLight({
  title,
  subtitle,
  buttonLabel,
  buttonLink,
}: {
  title: string;
  subtitle: string;
  buttonLabel: string;
  buttonLink: string;
}) {
  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Header>
      <Button size="large" kind="tertiary" href={buttonLink}>
        {buttonLabel}
      </Button>
    </Container>
  );
}

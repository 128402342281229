import { Trans, t } from "@lingui/macro";
import Image from "next/image";
import { up } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import styled from "styled-components";
import { Button } from "~/guidelines/Button";
import { Flex, Stack } from "~/guidelines/Flex";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { body3 } from "~/guidelines/Typography";
import { EyeCatchBox } from "./EyeCatchBox";
import CheckIn from "~/guidelines/Drawings/CheckIn.svg";
import WorkOnNotebook from "~/guidelines/Drawings/JeanWorkOnNotebook.svg";
import SleepingBell from "~/guidelines/Drawings/SleepingBell.svg";
import TakeYourPhone from "~/guidelines/Drawings/TakeYourPhone.svg";
import zigouigoui from "~/guidelines/Drawings/zigouigoui.svg";

const Wrapper = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(11, 5, 8)};
  width: 100%;
  align-items: center;
  ${mediaQueries.md()} {
    gap: ${({ theme }) => theme.spacing(6)};
    padding: 0 ${({ theme }) => theme.spacing(13)};
  }
`;

const Container = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(5)};
  padding-top: 20px;
  ${mediaQueries.md()} {
    gap: ${({ theme }) => theme.spacing(13)};
    padding-top: 0;
  }
`;

const Header = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(6)};
  align-items: center;
`;

const Heading = styled.h2`
  font-family: "Bely Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  margin: 0;

  ${mediaQueries.md()} {
    font-size: 48px;
  }

  color: ${({ theme }) => theme.color.text.blueDark};
`;

const SubTitle = styled.p`
  ${body3}
  margin: 0;
`;

const Content = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(6)};
  padding: ${({ theme }) => theme.spacing(6)} 0;
  align-items: flex-start;
  max-width: 1040px;
  align-items: flex-start;
  flex-direction: column;
  ${mediaQueries.md()} {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing(8)};
  }
`;

const Column = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => theme.spacing(7)};
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  border: 1px solid ${({ theme }) => theme.color.line.greyStroke};
  background: ${({ theme }) => theme.color.background.white};
  ${mediaQueries.md()} {
    padding: ${({ theme }) => theme.spacing(7, 11)};
    gap: ${({ theme }) => theme.spacing(9)};
  }
`;

const ImgFixedHeight = styled(Image)`
  height: 80px;
  width: auto;
`;

const Card = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(3)};
  align-self: stretch;
`;

const CardContent = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(6)};
  align-self: stretch;
`;

const Title = styled.h3`
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: ${({ theme }) => theme.color.text.blueDark};
  margin: 0;
  ${mediaQueries.md()} {
    font-size: 28px;
  }
`;

const TextDarkBackground = styled.p`
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  margin: 0;
  color: ${({ theme }) => theme.color.text.white};
  ${mediaQueries.md()} {
    font-size: 18px;
  }
`;

const FixedImage = styled(Image)`
  position: absolute;
  z-index: 1;
  right: -10px;
  top: 70px;
  ${mediaQueries.md()} {
    right: 15%;
    top: 30px;
  }
`;

export default function HowItWorks({ onClickCta }: { onClickCta: () => void }) {
  const isMdScreen = useBreakpoint(up("md"));

  return (
    <Wrapper>
      <FixedImage src={zigouigoui} alt="" width={isMdScreen ? "226" : "127"} />
      <Container>
        <Header>
          <Heading>
            <Trans>How does it work?</Trans>
          </Heading>
          <SubTitle>
            <Trans>
              Come with your hands in your pockets and your phone in your hands.
            </Trans>
          </SubTitle>
        </Header>
        <Content>
          <Column>
            <ImgFixedHeight src={WorkOnNotebook} alt=""></ImgFixedHeight>
            <Card>
              <SubTitle>
                <Trans>Step</Trans> 1
              </SubTitle>
              <CardContent>
                <Title>
                  <Trans>Book your room</Trans>
                </Title>
                <SubTitle>
                  <Trans>
                    Gogaille is present in Limoges, Orléans, ours, and this is
                    only the beginning.
                  </Trans>
                </SubTitle>
              </CardContent>
            </Card>
          </Column>
          <Column>
            <ImgFixedHeight src={CheckIn} alt=""></ImgFixedHeight>
            <Card>
              <SubTitle>
                <Trans>Step</Trans> 2
              </SubTitle>
              <CardContent>
                <Title>
                  <Trans>Register online</Trans>
                </Title>
                <SubTitle>
                  <Trans>
                    Once your room is booked, you will received a SMS to proceed
                    with your check-in.
                  </Trans>
                </SubTitle>
              </CardContent>
            </Card>
          </Column>
          <Column>
            <ImgFixedHeight src={TakeYourPhone} alt=""></ImgFixedHeight>
            <Card>
              <SubTitle>
                <Trans>Step</Trans> 3
              </SubTitle>
              <CardContent>
                <Title>
                  <Trans>Scan, enter</Trans>
                </Title>
                <SubTitle>
                  <Trans>
                    On the day of your arrival, at 1 AM, a SMS will tell you
                    your room number, accessible starting at 4 PM.
                  </Trans>
                </SubTitle>
              </CardContent>
            </Card>
          </Column>
        </Content>
      </Container>
      <EyeCatchBox title={t`Let's go Gogaille`}>
        <ImgFixedHeight src={SleepingBell} alt="" />
        <TextDarkBackground>
          <Trans>
            Book your stay and find out why Gogaille is going to conquer the
            world.
          </Trans>
        </TextDarkBackground>
        <Button
          size="large"
          kind="primary"
          fluid={isMdScreen ? false : true}
          onPress={onClickCta}
        >
          <Trans>Book</Trans>
        </Button>
      </EyeCatchBox>
    </Wrapper>
  );
}

import styled from "styled-components";
import mediaQueries from "~/guidelines/Theme/mediaQueries";

const EllipseTop = styled.div<{ $color: string }>`
  position: absolute;
  margin-top: -200px; /* Placer l'ellipse au-dessus du contenu */
  left: 50%; /* Centrer l'ellipse horizontalement */
  height: 300px; /* Hauteur de l'ellipse */
  background-color: ${({ $color }) => $color};
  border-radius: 120%; /* Forme de l'ellipse */
  transform: translateX(-50%); /* Centrer l'ellipse horizontalement */
  z-index: 10;

  width: 200%;
  ${mediaQueries.md()} {
    width: 120%;
  }
`;

const EllipseBottom = styled.div<{ $color: string }>`
  position: relative;
  margin-top: -180px; /* Placer l'ellipse au-dessus du contenu */
  left: 50%; /* Centrer l'ellipse horizontalement */
  height: 300px; /* Hauteur de l'ellipse */
  border-radius: 120%; /* Forme de l'ellipse */
  transform: translateX(-50%); /* Centrer l'ellipse horizontalement */
  z-index: 1;
  background-color: ${({ $color }) => $color};
  width: 200%;
  ${mediaQueries.md()} {
    width: 120%;
  }
`;

const EllipseContainer = styled.div<{ $marginTop: string }>`
  position: relative;
  z-index: 2;
  width: 100%;
  overflow: hidden;
  margin-top: ${({ $marginTop }) => $marginTop};
`;

const Wrapper = styled.div<{ $color: string }>`
  padding-top: 50px;
  position: relative;
  z-index: 2;
  background-color: ${({ $color }) => $color};
  width: 100%;
  ${mediaQueries.md()} {
    padding-top: 200px;
  }
`;

type Props = {
  children: React.ReactNode;
  top: boolean;
  bottom: boolean;
  color: string;
  topColor: string;
  marginTop?: string;
};

export function Ellipse({
  children,
  top,
  bottom,
  color,
  topColor,
  marginTop = "O",
}: Props) {
  return (
    <EllipseContainer $marginTop={marginTop}>
      {top ? <EllipseTop $color={topColor} /> : null}
      <Wrapper $color={color}>{children}</Wrapper>
      {bottom ? <EllipseBottom $color={color} /> : null}
    </EllipseContainer>
  );
}

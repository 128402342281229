import { Trans, t } from "@lingui/macro";
import Image from "next/image";
import { useState } from "react";
import styled from "styled-components";
import { HiddenOnMobile } from "~/components/Breakpoint";
import { gogailleHelpRoute } from "~/core/routes";
import { IconOnlyButton } from "~/guidelines/Button";
import { Flex, Stack } from "~/guidelines/Flex";
import { KeyboardArrowDown, KeyboardArrowUp } from "~/guidelines/Icon";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import theme from "~/guidelines/Theme/theme";
import { body3 } from "~/guidelines/Typography";
import CtaLight from "./CtaLight";
import FaqWomen from "~/guidelines/Drawings/FaqWomen.svg";

const Container = styled(Stack)`
  max-width: 1440px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(12, 5)};
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(8)};
  ${mediaQueries.md()} {
    padding: ${({ theme }) => theme.spacing(17, 13)};
    gap: ${({ theme }) => theme.spacing(13)};
  }
`;

const Header = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(6)};
  align-items: flex-start;
  align-self: flex-start;
  ${mediaQueries.md()} {
    padding: ${({ theme }) => theme.spacing(0, 18)};
  }
`;

const Content = styled(Stack)`
  max-width: 1040px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(7)};
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(5)};
  background: ${({ theme }) => theme.color.background.creamDark};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  position: relative;
  ${mediaQueries.md()} {
    gap: ${({ theme }) => theme.spacing(6)};
  }
`;

const AbsoluteImg = styled(Image)`
  position: absolute;
  right: 45px;
  top: -109px;
`;

const Title = styled.h2`
  font-family: "Bely Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  margin: 0;

  ${mediaQueries.md()} {
    font-size: 48px;
  }

  color: ${({ theme }) => theme.color.text.blueDark};
`;

const Subtitle = styled.p`
  margin: 0;
  ${body3}
`;

const Item = styled(Stack)`
  justify-content: center;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(4)};
  flex: 1 0 0;
`;

const Question = styled.h3`
  margin: 0;
  color: #211747;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  ${mediaQueries.md()} {
    font-size: 18px;
  }
`;

const Answer = styled.p`
  margin: 0;
  ${body3}
`;

const Card = styled(Flex)<{ $active: boolean }>`
  padding: ${({ theme }) => theme.spacing(6, 2, 6, 5)};
  align-items: flex-start;
  align-self: stretch;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  background: ${({ theme, $active }) =>
    $active ? theme.color.background.white : "transparent"};

  ${mediaQueries.md()} {
    padding: ${({ theme }) => theme.spacing(6, 7)};
  }

  & ${Answer} {
    display: ${({ $active }) => ($active ? "block" : "none")};
  }
`;

export default function Faq() {
  const [activeItem, setActiveItem] = useState<number | undefined>(undefined);
  const faqItems = [
    {
      question: t`How does breakfast work?`,
      answer: t`Breakfast is offered from 7 AM to 10 AM. It’s a standalone buffet. We offer hot drinks and fruit juices, milk and cereals. We complete this offer with pastries, bread, butter and jam. You will also find yogurt, cheese and fruit. We do not offer cold meats or eggs.`,
    },
    {
      question: t`How does online registration work?`,
      answer: t`Online registration consists of confirming your personal information, name, first name, email and telephone number as well as validating your ID (ID card or passport). We are committed to verifying your identity to ensure the safety of all our occupants.`,
    },
    {
      question: t`What happens if I run out of battery when I arrive?`,
      answer: t`Don't panic, we have installed intercoms outside and inside our buildings. They are always connected to our 24/7 customer service. We are therefore able to open all the doors of the building remotely. Also, we always have an induction phone charger in our lounge.`,
    },
    {
      question: t`What happens if I forget my phone in my room?`,
      answer: t`Don't panic, just go to the intercom located at the entrance to the building and contact our customer service. We will open your room door remotely.`,
    },
    {
      question: t`What happens if I don't have any network? `,
      answer: t`We have a great free wifi network, just connect to: GOGAILLE-PUBLIC`,
    },
    {
      question: t`I'm arriving earlier or leaving later, what can I do with my luggage?`,
      answer: t`We have independent luggage storage available in all our Gogailles. Also, on the day of your departure you still have access to all common areas until midnight.`,
    },
  ];

  return (
    <Container>
      <Header>
        <Title>FAQ</Title>
        <Subtitle>
          <Trans>
            If you have any questions, you may find the answer right here.
          </Trans>
        </Subtitle>
      </Header>
      <Content>
        <HiddenOnMobile>
          <AbsoluteImg src={FaqWomen} alt="" />
        </HiddenOnMobile>
        {faqItems.map((item, index) => (
          <Card key={index} $active={index === activeItem}>
            <Item>
              <Question>{item.question}</Question>
              <Answer>{item.answer}</Answer>
            </Item>
            <IconOnlyButton
              size="large"
              kind="quaternary"
              aria-label={`Toggle answer`}
              icon={
                index === activeItem ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown />
                )
              }
              iconColor={theme.color.brand.blueDark}
              onPress={() => setActiveItem(index)}
            />
          </Card>
        ))}
      </Content>
      <CtaLight
        title={t`Still have questions?`}
        subtitle={t`Our team will be delighted to help you.`}
        buttonLabel="Contact"
        buttonLink={gogailleHelpRoute()}
      />
    </Container>
  );
}

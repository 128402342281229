"use client";

import { t } from "@lingui/macro";
import { GetStaticProps, NextPage } from "next";
import { NextSeo } from "next-seo";
import { useRouter } from "next/compat/router";
import { ParsedUrlQuery } from "querystring";
import { useCallback } from "react";
import styled from "styled-components";
import {
  fetchBrandIdentity,
  useBrandIdentity,
} from "~/applications/Editorials/Api/useBrandIdentity";
import { BrandIdentity } from "~/applications/Editorials/Domain/BrandIdentity";
import NewHeader from "~/applications/Editorials/Ui/Shared/Header/NewHeader";
import {
  fetchDestinationPages,
  useDestinationPages,
} from "~/applications/OfferSearch/Api/useDestinationPages";
import {
  DestinationPage,
  destinationToMenuEntry,
} from "~/applications/OfferSearch/Domain/DestinationPage";
import BookDrawerModal, {
  BookingMode,
} from "~/applications/OfferSearch/Ui/BookDrawer/BookDrawerModal";
import { PublicPageSeo } from "~/components/Seo";
import { ResourceVisibility } from "~/core/ResourceVisibility";
import {
  AVAILABLE_LOCALES,
  DEFAULT_LOCALE,
  processUserLocale,
} from "~/core/locale";
import { useModalController } from "~/core/modal";
import { gogailleHomepageRoute, offersRoute } from "~/core/routes";
import { Stack } from "~/guidelines/Flex";
import theme from "~/guidelines/Theme/theme";
import { serverSideTranslations } from "~/translations/serverSideTranslations";
import { ServerSideGeneratedI18nNamespace } from "~/translations/types";
import AllBenefits from "./new-home/_components/AllBenefits";
import CtaOffers from "./new-home/_components/CtaOffers";
import Destinations from "./new-home/_components/Destinations";
import { Ellipse } from "./new-home/_components/Ellipse";
import Faq from "./new-home/_components/Faq";
import Footer from "./new-home/_components/Footer";
import Hero from "./new-home/_components/Hero";
import HowItWorks from "./new-home/_components/HowItWorks";
import KeyBenefits from "./new-home/_components/KeyBenefits";
import { Testimonials } from "./new-home/_components/Testimonials";
import OpenGraphImageHome from "~/applications/Editorials/Ui/Home/SEO/OpenGraphImageHome.jpg";

interface HomePageProps extends ServerSideGeneratedI18nNamespace {
  locale: AVAILABLE_LOCALES;
  prefetchedDestinationPages: Array<DestinationPage>;
  prefetchedBrandIdentity: BrandIdentity;
}

interface HomePageParams extends ParsedUrlQuery {
  destinationSlug: string;
}

export const getStaticProps: GetStaticProps<
  HomePageProps,
  HomePageParams
> = async (context) => {
  let locale = context.locale as "default" | AVAILABLE_LOCALES | undefined;

  if (locale === "default") {
    locale = undefined;
  }

  if (locale === undefined) {
    locale = processUserLocale({
      acceptLanguage: locale ?? "",
      defaultLocale: DEFAULT_LOCALE,
    });
  }

  // We fetch only public destination for page data
  const prefetchedDestinationPages = await fetchDestinationPages({
    locale,
    visibilities: [ResourceVisibility.PUBLIC],
  });

  const prefetchedBrandIdentity = await fetchBrandIdentity();

  return {
    props: {
      locale,
      prefetchedDestinationPages,
      prefetchedBrandIdentity,
      ...(await serverSideTranslations(locale)),
    },
  };
};

const PUBLIC_URL = process.env.PUBLIC_URL;

const Layout = styled(Stack)`
  background-color: ${({ theme }) => theme.color.background.cream};
  align-items: center;
`;

const HomePage: NextPage<HomePageProps> = (params: HomePageProps) => {
  const router = useRouter();
  const { destinationPages } = useDestinationPages(
    { locale: params.locale },
    params.prefetchedDestinationPages,
  );
  const bookDrawerModalController = useModalController(BookDrawerModal);
  const { brandIdentity } = useBrandIdentity();

  const onBookDestinationHandler = useCallback(() => {
    if (!destinationPages || destinationPages.length === 0) {
      return;
    }

    bookDrawerModalController.show({
      availableBookingMode: [BookingMode.Loges],
      initialBookingMode: BookingMode.Loges,
      initialDestinationSlug: destinationPages[0].seoSlug,
      destinations:
        destinationPages?.map(destinationToMenuEntry) ??
        params.prefetchedDestinationPages.map(destinationToMenuEntry),
      onSearchRoomOffer: ({
        destination,
        stayRequest,
        cancellationPolicyId,
      }) => {
        router?.push(
          offersRoute(destination.seoSlug, stayRequest, cancellationPolicyId),
        );
      },
      offerMaxCapacity: undefined,
      onNewSearch: undefined,
      zenChefRestaurantId: null,
    });
  }, [
    destinationPages,
    bookDrawerModalController,
    params.prefetchedDestinationPages,
    router,
  ]);

  if (!router) {
    return null;
  }

  const seoTitle = t`Gogaille | Official Site`;

  return (
    <Layout>
      <PublicPageSeo />
      <NextSeo
        title={seoTitle}
        description={t`Let's go to Gogaille. That's good, we have everything planned: a pillow that will make you sleep on your two ears and a good table to enjoy.`}
        openGraph={{
          type: "siteweb",
          title: seoTitle,
          description: t`Let's go to Gogaille. That's good, we have everything planned: a pillow that will make you sleep on your two ears and a good table to enjoy.`,
          url: PUBLIC_URL + router.asPath,
          images: [
            {
              url: OpenGraphImageHome.src,
              alt: seoTitle,
              width: OpenGraphImageHome.width,
              height: OpenGraphImageHome.height,
            },
          ],
        }}
        twitter={{
          cardType: "summary_large_image",
        }}
      />
      <NewHeader
        guestCanBookARoom={true}
        logoHref={gogailleHomepageRoute()}
        onBookDestination={onBookDestinationHandler}
        destinations={destinationPages?.map(destinationToMenuEntry) ?? []}
      />
      <Hero onClickCta={onBookDestinationHandler} />
      <KeyBenefits />
      <AllBenefits />
      <Ellipse
        top={true}
        bottom={true}
        color={theme.color.background.creamDark}
        topColor={theme.color.background.cream}
      >
        <HowItWorks onClickCta={onBookDestinationHandler} />
      </Ellipse>
      <Testimonials destinations={destinationPages} />
      <Destinations destinations={destinationPages} />
      <Faq />
      <CtaOffers onClickCta={onBookDestinationHandler} />
      <Ellipse
        top={true}
        bottom={false}
        color={theme.color.background.blueDarkMode}
        topColor={theme.color.background.cream}
        marginTop="-270px"
      >
        <Footer
          destinations={destinationPages}
          currentLocale={params.locale}
          pressKitUrl={
            brandIdentity !== null ? brandIdentity.pressKitUrl : null
          }
        />
      </Ellipse>
    </Layout>
  );
};

export default HomePage;

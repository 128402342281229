import { Trans, t } from "@lingui/macro";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Swiper from "swiper";
import { SwiperContainer, register } from "swiper/element/bundle";
import { DestinationPage } from "~/applications/OfferSearch/Domain/DestinationPage";
import { HiddenOnMobile } from "~/components/Breakpoint";
import { IconOnlyButton } from "~/guidelines/Button";
import { Flex, Stack } from "~/guidelines/Flex";
import { ArrowBack, ArrowForward } from "~/guidelines/Icon";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import theme from "~/guidelines/Theme/theme";
import MarketingLogos from "./MarketingLogos";
import TestimonialCard from "./TestimonialCard";
import ConfettisBg from "~/guidelines/Drawings/confetis.svg";
import GuideGo02 from "~/guidelines/Drawings/guide_go_02.svg";
import GuideGo03 from "~/guidelines/Drawings/guide_go_03.svg";

const Wrapper = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(8, 0)};
  align-items: center;
  max-width: 1440px;
  width: 100%;
  ${mediaQueries.md()} {
    gap: ${({ theme }) => theme.spacing(13)};
    padding: ${({ theme }) => theme.spacing(13, 18, 18)};
  }
`;

const Container = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(18)};
`;

const Control = styled(Flex)`
  padding: ${({ theme }) => theme.spacing(4)};
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(3)};
  align-items: center;
`;

const Content = styled(Flex)`
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(11)};
  align-items: center;

  ${mediaQueries.md()} {
    flex-basis: 768px;
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

const Title = styled.h2`
  font-family: "Bely Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  margin: 0;
  text-align: center;

  ${mediaQueries.md()} {
    font-size: 40px;
  }

  color: ${({ theme }) => theme.color.text.blueDark};
`;

const Actions = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(7)};
  align-items: center;
`;

const Pagination = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(3)};
  align-items: center;
`;

const Circle = styled.span`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  border: 1px solid ${({ theme }) => theme.color.text.blueDark};
`;

const CircleFilled = styled.span`
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.color.text.blueDark};
`;

const CircleIconOnlyButton = styled(IconOnlyButton)`
  border-color: ${({ theme }) => theme.color.text.blueDark};
  color: ${({ theme }) => theme.color.text.blueDark};
`;

const Carrousel = styled.div`
  position: relative;
  width: 100vw;
  padding: ${({ theme }) => theme.spacing(0, 5)};

  ${mediaQueries.md()} {
    width: 768px;
    padding: 0;
  }
`;

const Confettis = styled.div`
  position: relative;
  width: 166px;
  height: 128px;
  text-align: center;
`;

const Illustration = styled.div`
  position: absolute;
  bottom: 30px;
  width: 161px;
`;

const Header = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;
`;

export function Testimonials({
  destinations,
}: {
  destinations: DestinationPage[] | null;
}) {
  const swiperRef = useRef<SwiperContainer>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    register();

    if (swiperRef.current === null) {
      return;
    }

    swiperRef.current.initialize();
    swiperRef.current.swiper.on("activeIndexChange", (swiper: Swiper) => {
      setActiveIndex(swiper.activeIndex);
    });
  }, [swiperRef]);

  const handlePrev = () => {
    swiperRef.current?.swiper.slidePrev();
  };
  const handleNext = () => {
    swiperRef.current?.swiper.slideNext();
  };

  const data = [
    {
      text: t`Lovely stay here! So easy to use and the local breakfast was a treat. Beautiful facilities and a great short stay.`,
      guest: "Jezabell H.",
      loge: "Préfecture",
      destinationImage:
        destinations?.find((d) => d.seoSlug === "limoges")?.menuDrawing.url ??
        "",
      source: "google.com",
      nbStars: 5,
    },
    {
      text: t`Superb decoration, comfortable bedding, very clean, everything is designed to satisfy the customer, very beautiful terrace and large garden.`,
      guest: "Leslie A.",
      loge: "Arago",
      destinationImage:
        destinations?.find((d) => d.seoSlug === "orleans")?.menuDrawing.url ??
        "",
      source: "booking.com",
      nbStars: 5,
    },
    {
      text: t`Tourangeots wanting an intimate moment... and this hotel offered it to us in an elegant and refined setting. Self-managed hotel which denotes traditional hotels but which provides freedom like at home.`,
      guest: "Arnaud G.",
      loge: "Corneille",
      destinationImage:
        destinations?.find((d) => d.seoSlug === "tours")?.menuDrawing.url ?? "",
      source: "expedia",
      nbStars: 5,
    },
    {
      text: t`Great concept in complete autonomy but with ultra-responsive “customer service” if necessary. The feeling of being at home. We were amazed by the quality and comfort of the equipment, the pretty decoration and the surrounding calm. Everything has been extremely well thought out in detail. The value for money is great.`,
      guest: "Emilie L.",
      loge: "Gambetta",
      destinationImage:
        destinations?.find((d) => d.seoSlug === "limoges")?.menuDrawing.url ??
        "",
      source: "google.com",
      nbStars: 5,
    },
    {
      text: t`A tastefully decorated, welcoming and clean place. ++++ comfort, full breakfast. The location is ideal in a pedestrian area a stone's throw from the cathedral (tram stop). Independent access, but team can be reached if necessary, friendly and responsive.`,
      guest: "Isabelle B.C.",
      loge: "7 Dormants",
      destinationImage:
        destinations?.find((d) => d.seoSlug === "orleans")?.menuDrawing.url ??
        "",
      source: "booking.com",
      nbStars: 5,
    },
  ];

  return (
    <Wrapper>
      <Container>
        <HiddenOnMobile>
          <Control>
            <CircleIconOnlyButton
              kind="secondary"
              variant="dark_background"
              isDisabled={activeIndex == 0}
              onPress={handlePrev}
              icon={<ArrowBack />}
              iconColor={theme.color.text.blueDark}
            ></CircleIconOnlyButton>
          </Control>
        </HiddenOnMobile>
        <Content>
          <Header>
            <Confettis>
              <Image src={ConfettisBg} width="166" height="128" alt="" />
              <Illustration>
                <Image src={GuideGo02} alt="" />
                <Image src={GuideGo03} alt="" style={{ marginLeft: "-30px" }} />
              </Illustration>
            </Confettis>
            <Title>
              +1000 <Trans>unforgettable experiences</Trans>
            </Title>
          </Header>
          <Carrousel>
            <swiper-container init={false} ref={swiperRef}>
              {data.map((testimonial, index) => (
                <swiper-slide key={index}>
                  <TestimonialCard {...testimonial} />
                </swiper-slide>
              ))}
            </swiper-container>
          </Carrousel>
          <Actions>
            <Pagination>
              {swiperRef.current?.swiper.slides.map((_, index) =>
                index === activeIndex ? (
                  <CircleFilled key={index} />
                ) : (
                  <Circle key={index} />
                ),
              )}
            </Pagination>
            <MarketingLogos />
          </Actions>
        </Content>
        <HiddenOnMobile>
          <Control>
            <IconOnlyButton
              kind="secondary"
              variant="dark_background"
              isDisabled={activeIndex == data.length - 1}
              onPress={handleNext}
              icon={<ArrowForward />}
            ></IconOnlyButton>
          </Control>
        </HiddenOnMobile>
      </Container>
    </Wrapper>
  );
}

import React from "react";
import styled from "styled-components";

const Svg = styled.svg<{ color?: string }>`
  color: ${({ color, theme }) => color ?? theme.color.brand.blue};
  flex-shrink: 0;
`;

const PulseLoader = ({
  color,
  width = "100px",
}: {
  color?: string;
  width?: string;
}) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: "auto" }}
      width={width}
      viewBox="0 0 100 50"
      preserveAspectRatio="xMidYMid"
      color={color}
      data-testid={`pulse-loader`}
    >
      <g transform="translate(25 25)">
        <circle cx="0" cy="0" r="6" fill="currentColor">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.3333333333333333s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </g>
      <g transform="translate(50 25)">
        <circle cx="0" cy="0" r="6" fill="currentColor">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.16666666666666666s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </g>
      <g transform="translate(75 25)">
        <circle cx="0" cy="0" r="6" fill="currentColor">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="0s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </g>
    </Svg>
  );
};

export default PulseLoader;

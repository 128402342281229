"use client";

import { Trans, t } from "@lingui/macro";
import Image from "next/image";
import styled from "styled-components";
import { Flex } from "~/guidelines/Flex";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { body3 } from "~/guidelines/Typography";
import Monument from "~/applications/Editorials/Ui/Destination/DestinationDrawer/Drawings/PoitiersMonument.svg";
import PhotoLogeBed from "~/applications/Editorials/Ui/Home/Loge/Pictures/1.jpg";
import PhotoBreakfast from "~/applications/Editorials/Ui/Photos/Gogaille_Orleans_Arago-00072 1.jpg";
import PhotoTerrasse from "~/applications/Editorials/Ui/Photos/Gogaille_Orleans_Arago-2.jpg";
import PhotoQrCode from "~/applications/Editorials/Ui/Photos/qrcode.png";
import Breakfast from "~/guidelines/Drawings/Breakfast.svg";
import CuteCatNextToACouchAndAPlant from "~/guidelines/Drawings/CuteCatNextToACouchAndAPlant.svg";
import JeanAide from "~/guidelines/Drawings/JeanAide.svg";
import RoomConfiguration from "~/guidelines/Drawings/RoomConfiguration.svg";
import GuideGo1 from "~/guidelines/Drawings/guide_go_01.svg";

const Wrapper = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(8)};
  padding: ${({ theme }) => theme.spacing(5)};
  padding-top: ${({ theme }) => theme.spacing(8)};
  flex-direction: column;
  align-items: flex-start;
  max-width: 1440px;
  ${mediaQueries.md()} {
    gap: ${({ theme }) => theme.spacing(13)};
    padding: ${({ theme }) => theme.spacing(13)};
  }
`;

const Container = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(6)};
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  ${mediaQueries.md()} {
    gap: ${({ theme }) => theme.spacing(13)};
  }
`;

const Row = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(6)};
  align-self: stretch;
  flex-direction: column;

  ${mediaQueries.md()} {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing(9)};
  }
`;

const Column = styled(Flex)<{
  $order?: number;
}>`
  gap: ${({ theme }) => theme.spacing(6)};
  padding: ${({ theme }) => theme.spacing(7)};
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  order: ${({ $order = 0 }) => $order};

  ${mediaQueries.md()} {
    width: 320px;
    order: 0;
  }
`;

const ColumnFluid = styled(Column)`
  width: auto;
  flex: 1 0 0;
`;

const ColumnPhoto = styled(Column)`
  padding: 0;
  height: 320px;
  position: relative;

  ${mediaQueries.md()} {
    height: auto;
    width: 320px;
    flex-basis: 320px;
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

const ColumnBlue = styled(Column)`
  background-color: ${({ theme }) => theme.color.background.blueLight};
  border-radius: ${({ theme }) => theme.borderRadius.large};
`;

const ColumnBlueFluid = styled(ColumnBlue)`
  width: auto;
  flex: 1 0 0;
`;

const SmallImg = styled(Image)`
  max-height: 80px;
  width: auto;
`;

const CoverImg = styled(Image)`
  object-fit: cover;
  height: 320px;

  border-radius: ${({ theme }) => theme.borderRadius.large};
  ${mediaQueries.md()} {
    width: 320px;
    flex-shrink: 0;
  }
`;

const Title = styled.h2`
  text-align: left;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: ${({ theme }) => theme.color.text.blueDark};
  margin: 0;
  ${mediaQueries.md()} {
    font-size: 28px;
  }
`;

const Text = styled.p`
  ${body3}
  margin: 0;
`;

const CardPhotoBackground = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(6)};
  padding: ${({ theme }) => theme.spacing(7)};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  background-image: url(${PhotoLogeBed.src});
  background-color: #39347c;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply, normal;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  ${mediaQueries.md()} {
    padding: ${({ theme }) => theme.spacing(10)};
    gap: ${({ theme }) => theme.spacing(5)};
  }
`;

const Heading = styled.h2`
  font-family: "Bely Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  text-align: left;
  margin: 0;

  ${mediaQueries.md()} {
    font-size: 48px;
  }

  color: ${({ theme }) => theme.color.text.white};
`;

const SubTitle = styled.p`
  color: ${({ theme }) => theme.color.background.blueLight};
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
  ${mediaQueries.md()} {
    font-size: 18px;
  }
`;

export default function AllBenefits() {
  return (
    <Wrapper>
      <CardPhotoBackground>
        <Heading>
          <Trans>Sleeping with Gogaille.</Trans>
        </Heading>
        <SubTitle>
          {t`In the heart of cities where life is peaceful, you'll stay in historical buildings, always renovated with style and ingenuity.`}
        </SubTitle>
      </CardPhotoBackground>
      <Container>
        <Row>
          <ColumnBlue>
            <SmallImg src={JeanAide} alt="" />
            <Title>
              <Trans>Autonomous access</Trans>.
            </Title>
            <Text>
              <strong>{t`At Gogaille, access to the rooms is simple and quick: you access them using your smartphone`}</strong>{" "}
              {t`no need to wait for the turn at the reception.`}
            </Text>
          </ColumnBlue>
          <ColumnFluid>
            <SmallImg src={Monument} alt="" />
            <Title>
              <Trans>Buildings with character</Trans>.
            </Title>
            <Text>
              {t`Gogaille has a weakness for the heritage and memory of stones.`}{" "}
              <strong>{t`Staying with Gogaille means soaking up the soul of iconic monuments renovated with style`}</strong>{" "}
              {t`And it's strolling through the historic centers with the light feeling that you can go home by foot.`}
            </Text>
          </ColumnFluid>
          <ColumnPhoto style={{ position: "relative" }}>
            <CoverImg
              sizes="50vw, (max-width: 768px) 100vw"
              fill
              src={PhotoQrCode}
              alt=""
            />
          </ColumnPhoto>
        </Row>
        <Row>
          <ColumnPhoto $order={2} style={{ position: "relative" }}>
            <CoverImg
              sizes="50vw, (max-width: 768px) 100vw"
              fill
              src={PhotoBreakfast}
              alt=""
            />
          </ColumnPhoto>
          <ColumnBlueFluid $order={1}>
            <SmallImg src={Breakfast} alt="" />
            <Title>
              <Trans>Self-service breakfasts.</Trans>
            </Title>
            <Text>
              <strong>{t`Hot drinks, juices, pastries, yogurts, everything is included in the price of your night.`}</strong>{" "}
              {t`It's up to you to compose your breakfast, checked and restocked every half hour by our team.`}
            </Text>
          </ColumnBlueFluid>
          <Column $order={3}>
            <SmallImg src={CuteCatNextToACouchAndAPlant} alt="" />
            <Title>
              <Trans>Flex luggage.</Trans>
            </Title>
            <Text>
              {t`If your departure is planned for the very end of the day, you can safely leave your belongings in the luggage storage until midnight.`}
            </Text>
          </Column>
        </Row>
        <Row>
          <ColumnBlueFluid>
            <SmallImg src={GuideGo1} alt="" />
            <Title>
              <Trans>Refreshments available.</Trans>
            </Title>
            <Text>
              <strong>{t`In each Gogaille, you will find refreshments, a friendly place to have breakfast, work, have tea of coffee, reheat a dish or even chat over an improvised aperitif.`}</strong>{" "}
              {t`And you even have the right to enjoy the place until midnight on the day of your departure.`}
            </Text>
          </ColumnBlueFluid>
          <Column>
            <SmallImg src={RoomConfiguration} alt="" />
            <Title>
              <Trans>Quiet nights.</Trans>
            </Title>
            <Text>
              <strong>{t`The Gogaille promise is to live in the heart of the bustling city while enjoying a quiet room,`}</strong>{" "}
              {t`with both modern and high-end comfort. Proof that a perfect world is possible.`}
            </Text>
          </Column>
          <ColumnPhoto style={{ position: "relative" }}>
            <CoverImg
              fill
              sizes="50vw, (max-width: 768px) 100vw"
              src={PhotoTerrasse}
              alt=""
            />
          </ColumnPhoto>
        </Row>
      </Container>
    </Wrapper>
  );
}

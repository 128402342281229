import Image from "next/image";
import React from "react";
import styled from "styled-components";
import Stamp from "~/guidelines/Drawings/small-stamp.svg";

export type BackgroundColor = "pink" | "blueLight";

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
`;

const Container = styled.div<{
  $angle: number;
}>`
  background-image: url(${Stamp.src});
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 128px 100px;

  width: 128px;
  height: 100px;

  padding: ${({ theme }) => theme.spacing(5)};
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.16));
  transform: rotate(${({ $angle }) => $angle}deg);

  display: flex;
  flex-direction: column;
`;

const Content = styled.div<{
  $color: BackgroundColor;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme, $color }) => {
    switch ($color) {
      case "pink":
        return "#FCEBEA";
      case "blueLight":
        return theme.color.background.blueLight;
      default:
        return theme.color.background.blueLight;
    }
  }};
`;

const SmallDestinationStamp = ({
  image,
  color,
  angle,
}: {
  image: string;
  color: BackgroundColor;
  angle: number;
}) => {
  return (
    <Wrapper>
      <Container $angle={angle}>
        <Content $color={color}>
          <Image src={image} alt="" width={90} height={60} />
        </Content>
      </Container>
    </Wrapper>
  );
};

export default function SmallDestinationStampVariant({
  variant,
  image,
}: {
  variant: "1" | "2" | "3" | "4";
  image: string;
}) {
  switch (variant) {
    case "1":
      return <SmallDestinationStamp image={image} color="pink" angle={13} />;
    case "2":
      return (
        <SmallDestinationStamp image={image} color="blueLight" angle={-8} />
      );
    case "3":
      return <SmallDestinationStamp image={image} color="pink" angle={-25} />;
    case "4":
      return (
        <SmallDestinationStamp image={image} color="blueLight" angle={13} />
      );
  }
}
